<template>
  <div class="install-container">

    <div class="download-content" style="position: relative">
      <div class="download-top">
        <p>{{title}}网页插件"震撼来袭"</p>
      </div>

    <div class="installation-method">
      <!-- <div class="common-subtitle">
        <div class="subtitle-text">安装方法</div>
      </div> -->
      <div class="tab-diagram install-box">
        <div class="tab-one" v-for="item in browserList" :key="item.name" @click="switchover(item)"> 
          <span v-if="item == videoItem" style="color:#333;font-weight:800;">{{item.name}}</span>
          <span v-if="item != videoItem">{{item.name}}</span>
        </div>
      </div>
      <div class="installation-box">
        <div class="browser-box install width-big-box" v-if="videoItem">
          <video :src="videoItem.videoUrl" controls></video>
        </div>
      </div>
    </div>

      <a class="cur-brower" href="javascript:;" v-if="currentBrowser != null"
        @click="handleDownload(currentBrowser)">
        <div class="cur-hint">当前浏览器</div>
        <img :src="currentBrowser.icon" alt="" srcset="" width="60" class="curbrower-icon" />
        <div style="margin-left: 60px;color: rgb(255, 255, 255);font-size: 14px;width: 100%;">
          <div class="cur-title" style="font-size: 20px; margin-bottom: 2px">
            {{currentBrowser.name}}
          </div>
          <div class="cur-version">版本：{{currentBrowser.version}}</div>
        </div>
        <div class="down-cur">
          <img src="@/assets/plugin/download.svg" width="22" />
        </div>
      </a>


      <div class="otherbrower-box">

        <a class="otherbrower-item" v-for="item in browserList" :key="item.name" v-show="currentBrowser != item"
          href="javascript:;" @click="handleDownload(item)">
          <img :src="item.icon" alt="" srcset="" width="32" style="flex-shrink: 0" />
          <div style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 10px;
              width: 100%;
            ">
            <span class="otherbrower-item-text" style="line-height: 12px">{{item.name}}</span>
            <span style="line-height: 12px; color: #b8becc">版本:{{item.version}}</span>
          </div>
          <img src="@/assets/plugin/download.svg" alt="" srcset="" class="otherbrower-down-icon" width="12" />
        </a>


      </div>
    </div>



    <!-- <div class="change-log-box">
      <div class="common-subtitle exit-diagram">
        <div class="subtitle-text">名词解释</div>
      </div>
      <div class="log-content width-main-box" style="padding-bottom: 0px">
        <div class="log-one">
          <div class="log-title">Q：指数换算结果对应指标名称</div>
          <p style="padding-left: 15px">
            A：以下是生意参谋内指数指标名称与换算后对应的真实数值指标名称：
          </p>
          <p style="padding-left: 15px">1、交易指数 (换算结果) = 交易金额</p>
          <p style="padding-left: 15px">2、浏量指数 (换算结果) = 访客数(UV)</p>
          <p style="padding-left: 15px">3、客群指数 (换算结果) = 买家数</p>
          <p style="padding-left: 15px">
            4、支付转化指数 (换算结果) = 支付转化率(转化率)
          </p>
          <p style="padding-left: 15px">5、搜索人气 (换算结果) = 搜索人数</p>
          <p style="padding-left: 15px">6、搜索热度 (换算结果) = 搜索次数</p>
          <p style="padding-left: 15px">7、收藏人气 (换算结果) = 收藏人数</p>
          <p style="padding-left: 15px">8、加购人气 (换算结果) = 加购人数</p>
          <p style="padding-left: 15px">9、流失指数 (换算结果) = 流失金额</p>
          <p style="padding-left: 15px">10、流失人气 (换算结果) = 流失人数</p>
        </div>
        <div class="log-one">
          <div class="log-title">Q：MAC系统的浏览器支持吗？</div>
          <p style="padding-left: 15px">
            A：目前完美支持『MAC版chrome官方版浏览器』，安装与使用和PC版完全一样的便捷。
          </p>
        </div>
        <div class="log-one">
          <div class="log-title">Q：插件升级需要重新安装吗？</div>
          <p style="padding-left: 15px">
            A：ver8.2.0以前的版本需要重新下载最新的安装包安装，已经是VER8.2.0的插件用户可以享受插件自动更新，无需重新安装。
          </p>
        </div>
        <div class="log-one">
          <div class="log-title">Q：使用工具【需要注册】吗？</div>
          <p style="padding-left: 15px">
            A：工具基础功能无需要注册，只需安装好即可免费使用3天，如需长期使用，需在插件官网注册账号。
          </p>
        </div>
        <div class="log-one">
          <div class="log-title">Q：工具有绑定使用店铺吗？</div>
          <p style="padding-left: 15px">
            A：使用时不需要绑定店铺，无店铺限制，【任意一家】店铺的生意参谋只要用你的账号登录都可直接使用哦。
          </p>
        </div>
        <div class="log-one">
          <div class="log-title">Q：工具有绑定使用电脑吗？</div>
          <p style="padding-left: 15px">
            A：使用时也不需要绑定电脑，无使用电脑限制，【任意一台】电脑的生意参谋只要用你的账号登录都可直接使用哦。
          </p>
        </div>
      </div>
    </div> -->

  </div>
</template>



<script>
  import "@/utils/Browser"

  export default {
    data() {
      return {
        browserList: [{
            name: "Chrome版.插件",
            icon: require("@/assets/plugin/icon/chrome.png"),
            version: "1.0.0",
            coding:'Chrome',
            videoUrl:"https://www.renqichi.com/storageFiles/chromePlugin.mp4"
          },
          // {
          //   name: "360急速版.插件",
          //   icon: require("@/assets/plugin/icon/360fast.png"),
          //   version: "1.0.0",
          //   coding:'360EE',
          //   videoUrl:""
          // },
          {
            name: "360浏览器版.插件",
            icon: require("@/assets/plugin/icon/360browser.png"),
            version: "1.0.0",
            coding:'360SE',
            videoUrl:"https://www.renqichi.com/storageFiles/chromePlugin.mp4"
          },
          {
            name: "QQ浏览器版.插件",
            icon: require("@/assets/plugin/icon/qq.png"),
            version: "1.0.0",
            coding:'QQBrowser',
            videoUrl:"https://www.renqichi.com/storageFiles/chromePlugin.mp4"
          },
          {
            name: "搜狗浏览器版.插件",
            icon: require("@/assets/plugin/icon/souguo.png"),
            version: "1.0.0",
            coding:'Sogou',
            videoUrl:"https://www.renqichi.com/storageFiles/chromePlugin.mp4"
          },
          // {
          //   name: "UC浏览器版.插件",
          //   icon: require("@/assets/plugin/icon/uc.png"),
          //   version: "1.0.0",
          //   coding:'UC',
          //   videoUrl:""
          // },
          // {
          //   name: "其他浏览器.插件",
          //   icon: require("@/assets/plugin/icon/other.png"),
          //   version: "1.0.0",
          //   coding:'OTHER',
          //   videoUrl:""
          // },

        ],
        currentBrowser: null,
        infoBrowser:null,
        videoItem:null

      };
    },
    mounted() {
      this.infoBrowser = new Browser();
			if(this.infoBrowser.device!='Mobile'){
          let B_falt = this.browserList.some(item=>{
            if(this.infoBrowser.browser == item.coding){
              this.currentBrowser = item;
              return true
            }
            return false
          })
          if(!B_falt){
              this.currentBrowser = this.browserList[this.browserList.length-1];
          }
          //显示视频内容
          this.videoItem = this.currentBrowser;
      }
    },
    components: {

    },
    methods: {
      handleDownload(item) {
        if (!item.coding) {
          this.$message.success("暂不支持，敬请期待");
          return false;
        }
        //调用下载
        let baseHost = location.protocol + "//" + location.host;
        let downloadUrl = baseHost + "/plugin/renqichi-plugin.crx";
        if("Chrome" == item.coding){
            downloadUrl = baseHost + "/plugin/renqichi-plugin.zip";
        }
        
        window.open(downloadUrl);
      },

      switchover(item){
        this.videoItem = item;
      }
    },
    computed:{
      title(){
        return this.$store.state.title;
      }
    }
  };
</script>

<style lang="less" scoped>
  .install-container {
    margin: auto;
  }

  .download-content {
    width: 70%;
    margin: auto;
  }

  .download-top {
    font-size: 34px;
    color: rgb(26, 33, 51);
    margin-top: 30px;
    font-weight: 300;
    line-height: 40px;
    text-align: center;
  }

  .width-main-box {
    width: 1060px;
    box-sizing: border-box;
  }

  .main-color {
    color: #33aaff !important;
  }

  .small-font-size {
    font-size: 16px !important;
    line-height: 30px;
  }

  .dts-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .arrows {
    width: 0;
    height: 0;
    border-width: 0 15px 15px;
    border-style: solid;
    border-color: transparent transparent #fff;
  }

  .common-subtitle {
    line-height: 100px;
    font-size: 30px;
    text-align: center;
  }

  .exit-img-width {
    width: 160px;
    height: 160px;
  }

  .subtitle-text {
    font-size: 24px;
  }

  .subtitle-english {
    font-size: 14px;
  }

  .tab-diagram {
    margin: 15px auto 10px;
    font-size: 14px;
    color: #adadad;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  .tab-one {
    margin-right: 40px;
    cursor: pointer;
  }

  .tab-one:last-child {
    margin: 0;
  }

  .tab-one.sel {
    color: #33aaff;
    border-bottom: 1px solid;
  }

  .exit-diagram {
    margin-top: 60px;
  }

  .percent {
    margin-top: 70px;
    padding: 36px 0;
    background: #5657ff;
    width: 100%;
  }

  .exit-percent {
    width: 342px;
    color: #fff !important;
  }

  .sel-percent-style {
    color: #fff;
    border-bottom: 1px solid;
  }

  .percent-content {
    padding: 0 30px;
    margin: 24px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .percent-content-title {
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .percent-content-one {
    width: 37%;
  }

  .btn-percent {
    font-size: 15px;
    color: #f4f4ff;
    line-height: 27px;
    border: 1px solid;
    padding: 0 26px;
    border-radius: 20px;
  }

  .percent-content-box {
    height: 574px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    font-size: 14px;
    color: #666;
    padding: 0 20px;
    line-height: 30px;
  }

  .percent-center {
    padding: 0 24px 50px;
    box-sizing: border-box;
    width: 26%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .percent-change-btn {
    line-height: 44px;
    font-size: 18px;
    color: #5657ff;
    border-radius: 10px;
    background: #fff;
    width: 100%;
    text-align: center;
    margin-top: 184px;
  }

  .text-saoma {
    color: #fff;
    font-size: 14px;
    margin-top: 150px;
    margin-bottom: 10px;
  }

  .introduction {
    margin-top: 68px;
    width: 100%;
    padding-bottom: 130px;
  }

  .content {
    margin-top: 124px;
    width: 100%;
  }

  .exit-introduction {
    width: 270px;
  }

  .introduction-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
  }

  .intro-content-one {
    margin: 20px 1%;
    width: 22%;
    box-sizing: border-box;
    border: 1px solid #5657ff;
    font-size: 14px;
    height: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    height: 300px;
  }

  .icon-content {
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    flex-shrink: 0;
  }

  .icon-content.icon-1 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-3.png");
  }

  .icon-content.icon-2 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-4.png");
  }

  .icon-content.icon-3 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-5.png");
  }

  .icon-content.icon-4 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-6.png");
  }

  .icon-content.icon-5 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-7.png");
  }

  .icon-content.icon-6 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-8.png");
  }

  .icon-content.icon-7 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-9.png");
  }

  .icon-content.icon-8 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-10.png");
  }

  .content-text-title {
    line-height: 50px;
    color: #5657ff;
    font-size: 20px;
    flex-shrink: 0;
  }

  .introduction-highlights {
    color: #404040;
    line-height: 20px;
  }

  .foot {
    padding: 40px 0;
    line-height: 34px;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background: #383d40;
  }

  .header {
    position: relative;
    width: 100%;
  }

  .header-img-text {
    position: absolute;
    width: 33%;
    height: 32%;
    background: url("https://assets.diantoushi.com/cnd/img/dts-logotext.png") no-repeat;
    background-size: contain;
    top: 11%;
    left: 50%;
    transform: translateX(-50%);
  }

  .plug-in-box {
    position: absolute;
    bottom: 9%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .plug-box-common {
    display: flex;
    align-items: center;
  }

  .plug-content {
    font-size: 15px;
    background: #dcdcdc;
    color: #222222;
    border-radius: 5px;
    position: relative;
    margin: 30px 78px 0 26px;
    position: relative;
    border: 1px solid transparent;
    display: block;
    width: 275px;
    box-sizing: border-box;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 66px;
  }

  .plug-content:nth-of-type(3n) {
    margin-right: 0;
  }

  .plug-content:hover {
    color: #fff;
    background: #33aaff;
  }

  .plug-content:hover .context-main-plug {
    font-weight: bold;
  }

  .plug-content:hover .text {
    opacity: 1;
  }

  .plug-content:hover span {
    color: #fff !important;
  }

  .icon-plug-common {
    position: absolute;
    width: 60px;
    height: 60px;
    left: -26px;
    top: -1px;
  }

  .context-min-plug {
    font-size: 10px;
  }

  @media screen and (max-width: 1024px) {
    .context-main-plug {
      font-size: 12px;
    }
  }

  .card-box {
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
  }

  .card-box .one {
    width: 30%;
    position: relative;
    font-size: 17px;
    color: #000;
  }

  .scan-show-box {
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 18px #999;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .versions-box {
    font-size: 18px;
    margin-top: 30px;
  }

  .line-box-versions {
    display: flex;
    align-items: center;
    color: #7374ff;
  }

  .icon-versions {
    width: 28px;
    height: 28px;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 10px;
  }

  .icon-versions.iocn-1 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-16.png");
  }

  .icon-versions.iocn-2 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-17.png");
  }

  .icon-versions.iocn-3 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-18.png");
  }

  .icon-versions.iocn-4 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-19.png");
  }

  .icon-versions.iocn-5 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-20.png");
  }

  .icon-versions.iocn-6 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-21.png");
  }

  .icon-versions.iocn-7 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-22.png");
  }

  .versions-box .text {
    color: #404040;
    font-size: 14px;
    margin-top: 14px;
  }


  .btn-login-go {
    width: 140px;
    line-height: 40px;
    border-radius: 10px;
    background: #5657ff;
    font-size: 20px;
    color: #fff;
    text-align: center;
  }

  .sel-opert-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    margin-top: 14px;
  }

  .login-box {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 323px;
    border-radius: 10px;
    display: none;
    font-size: 15px;
    z-index: 1000000;
    box-shadow: 0 0 10px #999;
  }

  .login-border-style {
    border-radius: 5px;
    align-items: center;
    flex-direction: column;
    display: flex;
    overflow: hidden;
    width: 100%;
    background: #fff;
  }

  .login-box .top {
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .form-box {
    display: flex;
    align-items: center;
    margin-top: 15px;
    position: relative;
    text-align: right;
  }

  .input-style {
    width: 271px;
    height: 40px;
    border: 1px solid #dfdfe6;
    border-radius: 10px;
    outline: none;
    padding: 5px 10px;
    box-sizing: border-box;
  }

  .fond-pass {
    text-decoration: underline;
    text-align: right;
    color: #333;
  }

  .btn-login-sub {
    background: #33aaff;
    font-size: 20px;
    color: #fff;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .btn-register-sub {
    font-size: 13px;
    color: #33aaff;
    text-decoration: underline;
  }

  .content-register {
    background: #fff;
    border-radius: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 5px #999;
    top: -54px;
    padding-top: 50px;
  }

  .register-form-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 15px;
    padding-bottom: 74px;
    margin-left: 10%;
  }

  .get-code {
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    line-height: 32px;
    padding: 0 13px;
    background: #33aaff;
    margin-left: 10px;
    border: none;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
  }

  .message-box {
    padding: 20px 50px;
    background: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    z-index: 1000000;
  }

  .username {
    color: #33aaff !important;
  }

  .logo-toast {
    width: 122px;
    height: 43px;
    background: url("https://assets.diantoushi.com/cnd/img/dts-loginlogo.png") no-repeat;
    background-size: contain;
    margin-top: 18px;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    background: #d3d3d3;
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    border-radius: 3px;
    vertical-align: middle;
  }

  .watch-icon {
    width: 20px;
    height: 12px;
    position: absolute;
    right: -50px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .watch-icon.icon-watch {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-icon2.png");
  }

  .watch-icon.icon-unwatch {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-icon1.png");
  }

  .swiper-header-box {
    width: 100%;
  }

  .center-down-box {
    border-radius: 10px;
    background: #fff;
    position: relative;
    box-shadow: 0 0 10px #999;
    padding: 40px 60px;
    margin: 0 auto;
    top: -40px;
    z-index: 1000;
  }

  .center-content-box {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 28px;
    margin-top: 40px;
  }

  .installation-box {
  }

  .browser-box {
    margin: 0 auto;

    video {
      width: 100%;
    }
  }

  .log-content {
    line-height: 24px;
    color: #808080;
    font-size: 14px;
    margin: 0 auto;
    padding-bottom: 56px;
  }

  .log-one {
    padding: 28px 0;
    border-bottom: 1px dotted #e1e2e6;
  }

  .log-one:last-child {
    border: none;
  }

  .log-title {
    font-size: 18px;
    margin-bottom: 20px;
    color: #222;
    font-weight: bold;
  }

  .swiper-button-white-left {
    width: 70px;
    height: 70px;
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-rowleft.png");
    background-size: contain;
  }

  .swiper-button-white-right {
    width: 70px;
    height: 70px;
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-rowright.png");
    background-size: contain;
  }

  .swiper-pagination-bullet {
    background: #fff !important;
    width: 16px;
    height: 16px;
  }

  .installation-method {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .input-label-text {
    width: 72px;
    padding-right: 20px;
    position: absolute;
    left: -86px;
    top: 8px;
  }

  .usergrade-display {
    display: flex;
    align-items: center;
  }

  .upgrade {
    margin-left: 10px;
    padding: 0 10px;
    background: #33aaff;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    line-height: 20px;
  }

  .headline.sel {
    border-left: 1px solid #d2d2d2;
    border-right: 1px solid #d2d2d2;
  }

  .user-box-show {
    height: 100px;
    display: none;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    padding: 10px 20px;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    box-sizing: border-box;
  }

  .swiper-back-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    height: 100%;
  }

  .swiper-back-img.img1 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-down1.jpg");
  }

  .swiper-back-img.img2 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-down2.jpg");
  }

  .swiper-back-img.img3 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-down3.jpg");
  }

  .swiper-back-img.img4 {
    background-image: url("https://assets.diantoushi.com/cnd/img/dts-down4.jpg");
  }

  .register-back-img {
    background: url("https://assets.diantoushi.com/cnd/img/dts-23.png") 50% 50% no-repeat;
    background-size: cover;
    width: 100vw;
    height: 300px;
  }

  .control-text {
    width: 80%;
    padding-left: 42px;
  }

  .flex-box-plug {
    display: flex;
    flex-wrap: wrap;
  }

  .header-line {
    height: 2px;
    background: #33aaff;
    transition: all 0.5s;
    width: 98px;
    transform: translateX(168px);
  }

  .plug-msg {
    position: absolute;
    padding: 0px 10px;
    background: #666;
    color: #fff;
    font-size: 12px;
    top: -20px;
    right: -40px;
  }

  .plug-msg-bottom {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid #666;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    bottom: -6px;
    left: 0;
  }

  .download-tab-btn {
    width: 300px;
    height: 40px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #33aaff;
    color: #fff;
    border-radius: 4px;
    background: #33aaff;
    margin: 20px 100px 30px;
    cursor: pointer;
    position: relative;
  }

  .download-tab-btn:hover {
    color: #33aaff;
    background: #fff;
  }

  .download-tab-btn.sel {
    color: #33aaff;
    background: #fff;
  }

  .download-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .triangle-tab {
    position: absolute;
    top: 61px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    width: 20px;
    height: 20px;
    border-top: 1px solid #33aaff;
    border-left: 1px solid #33aaff;
    background: #f7f7f7;
    display: none;
  }

  .gjx-download {
    background: #f7f7f7;
    width: 100%;
  }

  .width-big-box {
    // width: 65%;
    width: 832px;
    height: 472px;
  }

  .width-big-box img {
    max-width: 100%;

  }

  .download-hint-text {
    padding-top: 30px;
    font-size: 12px;
    text-align: center;
  }

  .show-more {
    padding: 2px 0;
    background: #3af;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    width: 70px;
    margin: 0 auto;
    text-align: center;
  }

  .show-more:hover {
    background: #2988cc;
  }

  @media only screen and (max-width: 429px) and (min-width: 200px) {
    body {
      min-width: 300px;
      background: #fff;
    }

    .width-main-box {
      width: 300px;
    }

    .title {
      height: 50px;
      width: 90% !important;
    }

    .logo {
      width: 70px;
      height: 14px;
    }

    .uint .headline {
      padding: 2px 4px;
      font-size: 12px;
    }

    .swiper-container {
      height: 140px !important;
    }

    .subtitle-text {
      font-size: 16px;
    }

    .subtitle-english {
      font-size: 12px;
    }

    .common-subtitle {
      width: 132px !important;
    }

    .center-content-box {
      margin-top: 20px;
    }

    .center-down-box {
      padding: 10px;
    }

    .center-content-box {
      flex-direction: column;
    }

    .exit-img-width {
      width: 100px;
      height: 100px;
    }

    .control-text {
      padding-top: 4px;
      padding-left: 0;
      text-indent: 2em;
      width: 96%;
      line-height: 20px;
      font-size: 12px;
    }

    .icon-plug-common {
      left: -18px;
      width: 40px;
      height: 40px;
    }

    .plug-content {
      padding-left: 30px;
      margin-top: 15px;
      height: 40px;
      line-height: 17px;
      width: auto;
      margin: 10px 0 0 10px;
      padding-right: 2px;
    }

    .flex-box-plug {
      justify-content: space-between;
    }

    .installation-method {
      margin-top: 28px;
    }

    .tab-diagram {
      margin: 12 auto 10px;
      width: 80%;
    }

    .tab-one {
      margin-right: 10px;
    }

    video {
      width: 100%;
      height: auto !important;
    }

    .browser-box {
      height: auto;
    }

    .exit-diagram {
      margin-top: 24px;
    }

    .log-content {
      width: 86%;
    }

    .log-one {
      padding: 14px 0;
    }

    .log-title {
      margin-bottom: 10px;
    }

    .foot {
      padding: 20px 10px;
      line-height: 26px;
      font-size: 12px;
      box-sizing: border-box;
    }
  }

  /* 下载方式 */

  .download-box {
    width: 100%;
    height: 642px;
    background: url("https://assets.diantoushi.com/cnd/img/dts_0326_3.png") 50% 50% no-repeat;
    background-size: cover;
  }

  .download-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .cur-brower {
    width: 310px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #3af;
    position: relative;
    left: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 0 10px #b8c2e6;
  }

  .down-cur {
    width: 60px;
    background: #fff;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .curbrower-icon {
    flex-shrink: 0;
    position: absolute;
    left: -30px;
    top: 0;
  }

  .otherbrower-box {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    display: flex;
  }

  .otherbrower-item {
    width: 186px;
    background: #fff;
    padding: 6px;
    font-size: 12px;
    color: #7a8499;
    display: flex;
    border-radius: 30px;
    border: 1px solid #fff;
    box-shadow: 0 0 10px #b8c2e6;
    height: 44px;
    box-sizing: border-box;
    margin: 0 50px;
  }

  .otherbrower-item:hover .otherbrower-item-text {
    color: #1a2133;
  }

  .otherbrower-item:hover {
    border: 1px solid #b2dfff;
  }

  .otherbrower-item:hover .otherbrower-down-icon {
    display: block;
  }

  .otherbrower-down-icon {
    margin-right: 15px;
    flex-shrink: 0;
    display: none;
  }

  .cur-hint {
    background: #f74e31;
    padding: 0 12px;
    font-size: 12px;
    color: #fff;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-top-left-radius: 30px;
    position: absolute;
    right: 0;
    top: -17px;
  }

  #header {
    position: absolute;
    top: 0;
    z-index: 1000;
  }

  .free-switch .el-switch__core:after {
    background-color: #3af;
  }

  a {
    text-decoration: none;
  }
</style>